<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
            @click="showFormModal(null)"
            class="px-3 text-capitalize"
            variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد سئو خدمات روم تور
        </b-button>
      </b-col>
    </b-row>
    <div v-if="seoCities">
      <div class="d-flex justify-content-between align-items-center">
        <per-page class="col-sm-12 col-md-3 col-lg-2" v-model="perPage" />
        <!-- <search
          ref="residencesSearchComponent"
          class="w-50"
          v-model="searchInput"
        /> -->
      </div>

      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th>عنوان</b-th>
            <b-th>کلمات کلیدی</b-th>
            <b-th> توضیحات متا</b-th>
            <b-th>  سایر بخش ها </b-th>
            <b-th>اسلاگ شهر</b-th>
            <b-th>خدمات</b-th>
            <b-th>دسته بندی</b-th>
<!--            <b-th>تاریخ</b-th>-->
            <b-th>وضعیت</b-th>
            <b-th>عملیات</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="service in seoCities" :key="service.id">
            <b-th >{{ service.title }}</b-th>
            <b-td>{{ service.keywords }}</b-td>
            <b-td>{{ service.description }}</b-td>
            <b-td>
              <span>
                <strong>سوالات متداول : </strong>
                <b-badge variant="primary" v-if="service.faqs">
                  دارد
                </b-badge>
                <b-badge v-else>
                  ندارد
                </b-badge>
                <br/>
               <strong>تگ ها : </strong>
                <b-badge variant="primary" v-if="service.tags">
                  دارد
                </b-badge>
                <b-badge v-else>
                  ندارد
                </b-badge>
              </span>
            </b-td>
            <b-td>
              {{ service.slug }} - {{service.city_name}}
            </b-td>
            <b-td>
                   <b-badge v-if="service.service==='residence'">
                    اقامتگاه
                     </b-badge>
              <b-badge v-if="service.service==='experience'">
                    تجربه
                     </b-badge>
              <b-badge v-if="service.service==='car'">
                    ترانسفر
                     </b-badge>
              <b-badge v-if="service.service==='tour'">
                    تور گردشگری
                     </b-badge>
            </b-td>
            <b-td>
              <span v-if="service.category_space" style=" background-color: yellow;padding: 2px">
                {{ service.category_space.name }}
              </span>
              <span v-if="service.category_event" style=" background-color: yellow;padding: 2px">
                {{ service.category_event.name }}
              </span>
              <span v-if="service.category_car" style=" background-color: yellow;padding: 2px">
                {{ service.category_car.name }}
              </span>
              <span v-if="service.category_tour" style=" background-color: yellow;padding: 2px">
                {{ service.category_tour.name }}
              </span>

              <span v-else>
                ----
              </span>
            </b-td>
<!--            <b-td>
              {{
                new Date(service.created_at).toLocaleString("fa-IR", {
                  timeZone: "Iran",
                })
              }}
            </b-td>-->
            <b-td>
              <b-col
                  class="d-flex align-items-center pl-0"
                  :class="
                  service.status != 'active' ? 'text-danger' : 'text-success'
                "
              >
                {{ service.status == "active" ? "" : "غیر" }}فعال
              </b-col></b-td
            >
            <b-td>
              <b-col class="d-flex align-items-center">
<!--                <b-button
                    @click="showConfirmModal(service)"
                    variant="flat-danger"
                    class="font-weight-bolder"
                >
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>-->
                <b-button
                    @click="showFormModal(service)"
                    variant="flat-secondary"
                    class="font-weight-bolder"
                >
                  <feather-icon icon="Edit2Icon" size="20" />
                </b-button> </b-col
              ></b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <pagination
          ref="residencesPaginationComponent"
          v-model="page"
          :paginationData="paginationData"
      />
    </div>
    <b-sidebar
        width="70%"
        v-model="formModal"
        right
        backdrop
        shadow
        bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
                style="background-color: rgba(75, 75, 75, 0.12)"
                @click="formModal = false"
                variant="flat-dark"
                class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش دسته بندی" : "ایجاد دسته بندی" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-1">
          <label for="name" class="text-capitalize"> عنوان سئو (title)   : </label>
          <b-form-input @input="removeErrors('name')" id="name" v-model="serviceInput.title" type="text" />
          <ErrorMessage
              errorType="name"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="slug" class="text-capitalize"> کلمات کلیدی keywords : </label>
          <b-form-input @input="removeErrors('slug')" id="slug" v-model="serviceInput.keywords" type="text" />
          <ErrorMessage
              errorType="slug"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>

        <b-form-group class="mt-1">
          <label for="description" class="text-capitalize"> توضیحات سئو meta Description : </label>
          <b-form-input
              id="description"
              v-model="serviceInput.description"
              type="text"
              @input="removeErrors('description')"
          />
          <ErrorMessage
              errorType="description"
              :errorMessages="errorMessage"
          ></ErrorMessage>
        </b-form-group>

        <b-col class="my-1 mb-6">
          <b-form-group>
            <label for="description" class="text-capitalize"
            >توضیحات در مورد شهر :<span class="text-danger">*</span></label
            >
            <quill-editor
                style=" direction: rtl;text-align: right;height: 200px"
                v-model="serviceInput.body"
            />
            <!--              <vue-editor
                            v-model="tourInput.description"
                            :editorToolbar="customToolbar"
                          ></vue-editor>-->
          </b-form-group>
        </b-col>






        <b-row
            style="margin-top: 35px"
            class=""
            v-for="(question, index) in serviceInput.faqs"
            :key="'faqs_' + index"
        >
          <b-col key="key-question-name" md="3">
            <b-form-group>
              <label for="question_name" class="text-capitalize"
              >عنوان سوال:<span class="text-danger">*</span></label
              >
              <b-form-input
                  lazy
                  key="question_name"
                  v-model="question.question"
                  id="question_name"
                  placeholder="استانبول در کجا اقامتگاه بگیرم ؟"
              />
            </b-form-group>
          </b-col>
          <b-col key="key_answer_question" md="3">
            <b-form-group>
              <label for="key_answer_question1" class="text-capitalize"
              >پاسخ سوال:<span class="text-danger">*</span></label
              >
              <b-form-input
                  lazy
                  key="key_answer_question1"
                  v-model="question.answer"
                  id="key_answer_question1"
                  placeholder="بیا فقط مجتمع احمد اینا "
              />
            </b-form-group>
          </b-col>
          <b-button
              @click="removeQuestion(index)"
              variant="flat-danger"
              class="font-weight-bolder"
          >
            <feather-icon icon="Trash2Icon" size="20" />
          </b-button>
        </b-row>
        <b-row class="mb-5">
          <b-col md="3"     style="margin-top: 35px">
            <b-button @click="newQuestion">افزودن سوال</b-button>
          </b-col>
        </b-row>


        <b-row
            style="margin-top: 35px"
            v-for="(tag, index) in serviceInput.tags"
            :key="'tags_' + index"
        >
          <b-col key="key-tag-name" md="3">
            <b-form-group>
              <label for="tag_name" class="text-capitalize"
              >عنوان تگ :<span class="text-danger">*</span></label
              >
              <b-form-input
                  lazy
                  key="tag_name"
                  v-model="tag.title"
                  id="tag_name"
                  placeholder="آپارتمان استانبول"
              />
            </b-form-group>
          </b-col>
          <b-col key="key_answer_tag" md="3">
            <b-form-group>
              <label for="key_answer_tag1" class="text-capitalize"
              >آدرس تگ (بصورت مسیری بدون در نظرگرفتن آدرس اصلی مثل : /space) :<span class="text-danger">*</span></label
              >
              <b-form-input
                  lazy
                  style="direction: ltr"
                  key="key_answer_tag1"
                  v-model="tag.url"
                  id="key_answer_tag1"
                  placeholder="/space "
              />
            </b-form-group>
          </b-col>
          <b-button
              @click="removeTag(index)"
              variant="flat-danger"
              class="font-weight-bolder"
          >
            <feather-icon icon="Trash2Icon" size="20" />
          </b-button>
        </b-row>
        <b-row class="mb-5">
          <b-col md="3"     style="margin-top: 35px">
            <b-button @click="newTag">افزودن تگ</b-button>
          </b-col>
        </b-row>


        <b-row>
          <b-col md="6">
            <b-form-group style="margin-top: 75px !important;">
              <label for="residence_area" class="text-capitalize">
                انتخاب خدمات .<span class="text-danger">*</span>
              </label>
              <div>
                <div><strong> residence :  </strong> اقامتگاه </div>
<!--                <div><strong> experience :  </strong> تجربه </div>
                <div><strong> car :  </strong> ترانسفر </div>
                <div><strong> tour :  </strong> تور </div>-->
              </div>
              <v-select
                  id="residence_area"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="serviceInput.service"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeService"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group class="" v-if="serviceInput.service==='residence'">
              <label for="city_province" class="text-capitalize">  انتخاب دسته بندی اقامتگاه :</label>
              <v-select
                  :loading="showGettingCityLoading"
                  id="city_province"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="serviceInput.category_space_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="(category) => category.id"
                  :options="residence_categories"
                  placeholder="برای جستجو تایپ کنید"
              />
            </b-form-group>

<!--            <b-form-group class="" v-if="serviceInput.service==='experience'">
              <label for="city_province" class="text-capitalize">  انتخاب دسته بندی تجربه :</label>
              <v-select
                  :loading="showGettingCityLoading"
                  id="city_province"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="serviceInput.category_event_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="(category) => category.id"
                  :options="event_categories"
                  placeholder="برای جستجو تایپ کنید"
              />
            </b-form-group>

            <b-form-group class="" v-if="serviceInput.service==='car'">
              <label for="city_province" class="text-capitalize">  انتخاب دسته بندی وسیله نقلیه :</label>
              <v-select
                  :loading="showGettingCityLoading"
                  id="city_province"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="serviceInput.category_car_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="(category) => category.id"
                  :options="car_categories"
                  placeholder="برای جستجو تایپ کنید"
              />
            </b-form-group>

            <b-form-group class="" v-if="serviceInput.service==='tour'">
              <label for="city_province" class="text-capitalize">  انتخاب دسته بندی تور :</label>
              <v-select
                  :loading="showGettingCityLoading"
                  id="city_province"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="serviceInput.category_tour_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="(category) => category.id"
                  :options="tour_categories"
                  placeholder="برای جستجو تایپ کنید"
              />
            </b-form-group>-->
          </b-col>
        </b-row>


        <b-row>

          <b-col md="6">
            <b-form-group class="">
              <label for="city_province" class="text-capitalize">
                استان :</label
              >
              <v-select
                  id="city_province"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="serviceInput.state_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="(province) => province.id"
                  :options="provinces"
                  placeholder="برای جستجو تایپ کنید"
                  @input="getSelectedProvincesCity"
              />
              <!-- <ErrorMessage
              errorType="state"
              :errorMessages="errorMessage"
            ></ErrorMessage> -->
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group class="">
              <label for="city_province" class="text-capitalize">  شهر :</label>
              <v-select
                  :loading="showGettingCityLoading"
                  id="city_province"
                  :components="{ OpenIndicator }"
                  class="bg-white"
                  :clearable="false"
                  v-model="serviceInput.city_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :reduce="(city) => city.id"
                  :options="cities"
                  placeholder="برای جستجو تایپ کنید"
              />
              <!-- <ErrorMessage
              errorType="city"
              :errorMessages="errorMessage"
            ></ErrorMessage> -->
            </b-form-group>
          </b-col>
        </b-row>



        <!--        <b-col md="12">
                  <b-form-group class="mt-6">
                    <label for="imageIcon" class="text-capitalize">
                      آپلود تصویر شاخص :
                    </label>
                    <FilePicker
                        @inputFile="inputIconImageFile"
                        :url="iconImageurl"
                        ref="iconImageFilePicker"
                    ></FilePicker>
                    <ErrorMessage
                        errorType="image"
                        :errorMessages="errorMessage"
                    ></ErrorMessage>
                  </b-form-group>
                </b-col>-->
        <b-form-group class="mt-1 col-md-6" v-slot="{ ariaDescribedby }">
          <label for="province_status" class="text-capitalize">
            وضعیت :<span class="text-danger">*</span>
          </label>
          <b-form-checkbox
              v-model="serviceInput.status"
              :aria-describedby="ariaDescribedby"
              value="active"
          >فعال</b-form-checkbox
          >
          <!-- @input="removeErrors('status')" -->
          <b-form-checkbox
              v-model="serviceInput.status"
              :aria-describedby="ariaDescribedby"
              value="inactive"
          >پیش نویس</b-form-checkbox
          >
          <!-- @input="removeErrors('status')"
      <ErrorMessage
        errorType="status"
        :errorMessages="errorMessage"
      ></ErrorMessage> -->
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
              class="text-capitalize mr-2"
              @click="formModal = false"
              variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormCheckbox,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd, BBadge,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import ErrorMessage from "@/components/ErrorMessage.vue";
import FilePicker from "@/components/FilePicker.vue";
import QuillEditor from "vue-quill-editor/src/editor.vue";
import types from "vue-good-table/src/components/types";
import {latLng} from "leaflet";

export default {
  computed: {
    types() {
      return types
    }
  },
  components: {
    BBadge,
    QuillEditor,
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    ErrorMessage,
    BFormCheckbox,
    FilePicker,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  data() {
    return {
      typeService: [
        "residence",
        "experience",
        "car",
        "tour",

      ],

      iconImageurl:"",
      errorMessage: {},
      perPage: 10,
      page: 1,
      buildings: [],
      provinces: [],

      cities: [],
      residence_categories: [],
      event_categories: [],
      car_categories: [],
      tour_categories: [],

      seoCities: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "تصویر آیکن",
          slug: "image_icon",
          sortable: false,
        },
        {
          title: "آیکن",
          slug: "icon",
          sortable: false,
        },
        {
          title: "عنوان",
          slug: "name",
          sortable: true,
        },
        {
          title: "توضیحات",
          slug: "description",
          sortable: true,
        },
        {
          title: "تاریخ ایجاد",
          slug: "created_at",
          sortable: true,
        },

        {
          title: "وضعیت",
          slug: "status",
          sortable: false,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
            createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      buildingsCancelToken: undefined,
      serviceInput: {
        id: "",
        title: "",
        keywords:"",
        description: "",
        state_id: "",
        city_id: "",
        category_space_id: "",
        category_event_id: "",
        category_tour_id: "",
        category_car_id: "",
        body: "",
        status:"active",
        service:"",
        image: null,
        faqs: [],
        tags: [],
      },
      showGettingCityLoading: false,
      editMode: false,
      formModal: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getServices();
    this.getBuildings();
    this.getProvinces();
    this.getSpaceCategories();
    this.getEventCategories();
    this.getTourCategories();
    this.getCarCategories();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getServices();
    },
    perPage(value) {
      this.page = 1;
      this.getServices();
    },
    page(value) {
      this.getServices();
    },
  },
  methods: {
    async getSpaceCategories() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/residences/categories",
        // params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.residence_categories = data.data;
           // this.paginationData = data.meta;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    async getEventCategories() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/experiences/categories",
        // params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.event_categories = data.data;
          //  this.paginationData = data.meta;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    async getTourCategories() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/tours/categories",
        // params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.tour_categories = data.data;
          //  this.paginationData = data.meta;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    async getCarCategories() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/cars/categories",
        // params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.car_categories = data.data;
            //this.paginationData = data.meta;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    getProvinces(search, loading) {
      if (typeof this.provincesCancelToken != typeof undefined) {
        this.provincesCancelToken.cancel(
            "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.provincesCancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
      };

      const requestData = {
        method: "get",
        url: "/admin/states",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.provinces = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getSelectedProvincesCity(provinceId) {
      this.showGettingCityLoading = true;
      this.getCityByProvinceId(provinceId);
    },
    async getCityByProvinceId(provinceId) {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: false,
        // search: this.searchInput,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: `/admin/cities/${provinceId}`,
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.cities = data.data;
            this.paginationData = data.meta;
            this.showGettingCityLoading = false;
            if (!this.editMode) {
              this.experienceInput.city_id = this.cities[0]["id"];
            }
          })
          .catch((err) => {
            console.log(err);
            this.showGettingCityLoading = false;
          });
    },




    inputIconImageFile(file) {
      this.removeErrors("image");
      this.serviceInput.image = file;
    },
    removeQuestion(index) {
      this.serviceInput.faqs.splice(index, 1);
    },
    newQuestion() {
      if (!this.serviceInput.faqs) {
        this.serviceInput.faqs = [];
      }
      this.serviceInput.faqs.push({ question: "", answer: "" });
      // this.serviceInput.faqs.push({ question: "", answer: "" });
      //console.log(this.serviceInput.faqs);
    },
    removeTag(index) {
      this.serviceInput.tags.splice(index, 1);
    },
    newTag() {
      if (!this.serviceInput.tags) {
        this.serviceInput.tags = [];
      }
      this.serviceInput.tags.push({ title: "", url: "" });
    },

    removeErrors(data) {
      if (
          typeof this.errorMessage[data] !== "undefined" &&
          this.errorMessage[data].length > 0
      ) {
        // this.errorMessage[data][0]="salllam"
        delete this.errorMessage[data];
        this.errorMessage = Object.assign(
            {},
            this.errorMessage,
            this.errorMessage
        );
        console.log(data, this.errorMessage);
      }
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getServices();
    },
    async getServices() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: true,
        per_page: this.perPage,
        page: this.page,
        // per_page: this.perPage,
        // page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/seoCities",
         params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.seoCities = data.data;
             this.paginationData = data.meta;
          })
          .catch((err) => {
            console.log(err);
          });
    },

    showConfirmModal(service) {
      this.$bvModal
          .msgBoxConfirm(
              `آیا از حذف خدمات مطمئن هستید ؟  : ${service.title}.`,
              {
                title: this.$t("Please Confirm"),
                size: "sm",
                okVariant: "primary",
                okTitle: this.$t("Yes"),
                cancelTitle: this.$t("No"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              }
          )
          .then(async (confirmed) => {
            if (confirmed) {
              let residenceIndex = 0;
              this.seoCities = await this.seoCities.filter(
                  (residenceItem, index) => {
                    if (residenceItem.id == service.id) {
                      residenceIndex = index;
                    }
                    return residenceItem.id !== service.id;
                  }
              );

              const requestData = {
                method: "delete",
                url: `/admin/seoCities/${service.id}`,
              };
              this.$http(requestData)
                  .then((response) => {
                    this.$bvToast.toast(
                        `خدمات: ${service.title}  با موفقیت حذف شد.`,
                        {
                          title: this.$t("Done"),
                          variant: "success",
                          solid: true,
                        }
                    );
                  })
                  .catch((err) => {
                    let message = this.$t("Unable to delete!");
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    )
                      message = err.response.data.message;
                    this.$bvToast.toast(message, {
                      title: this.$t("Error"),
                      variant: "danger",
                      solid: true,
                    });
                    this.seoCities.splice(residenceIndex, 0, service);
                  });
            }
          });
    },
    showFormModal(service) {
      if (service) {
        this.serviceInput = {
          id: service.id,
          title: service.title,
          keywords:service.keywords,
          description: service.description,
          body: service.body,
          state_id: service.state.id,
          city_id: service.city.id,
          category_space_id: service.category_space.id,
          category_event_id: service.category_event.id,
          category_car_id: service.category_car.id,
          category_tour_id: service.category_tour.id,
          faqs: service.faqs,
          tags: service.tags,
          status: service.status,
          service: service.service,
          image: null,
        };
        // this.$refs.iconImageFilePicker.clearFileInput()
        // this.iconImageurl=service.image.path
        this.editMode = true;
      } else {
        this.serviceInput = {
          title: "",
          keywords: "",
          body: "",
          description: "",
          state_id: "",
          city_id: "",
          status: "",
          tags: "",
          faqs: "",
          image: "",
          service: "",
        };
        // this.$refs.iconImageFilePicker.clearFileInput()
        //  this.iconImageurl=""
        this.editMode = false;
      }
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.serviceInput.title) {
        errorMessage = "لطفا عنوان خدمات را وارد کنید";
      }
/*
      let formData = new FormData();

      if (this.serviceInput.title) {
        formData.append("title", this.serviceInput.title);
      }
      if (this.serviceInput.keywords) {
        formData.append("keywords", this.serviceInput.keywords);
      }
      if (this.serviceInput.status) {
        formData.append("status", this.serviceInput.status);
      }
      if (this.serviceInput.service) {
        formData.append("service", this.serviceInput.service);
      }
      if (this.serviceInput.city_id) {
        formData.append("city_id", this.serviceInput.city_id);
      }
      if (this.serviceInput.state_id) {
        formData.append("state_id", this.serviceInput.state_id);
      }
      if (this.serviceInput.description) {
        formData.append("description", this.serviceInput.description);
      }
      if (this.serviceInput.body) {
        formData.append("body", this.serviceInput.body);
      }
      /!*      if (this.serviceInput.image) {
              formData.append("image", this.serviceInput.image);
            }*!/
      if (this.serviceInput.faqs) {
        formData.append("faqs", this.serviceInput.faqs);
      }
      if (this.serviceInput.tags) {
        formData.append("tags", this.serviceInput.tags);
      }*/
      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

      const requestData = {
        method: this.editMode ? "post" : "post",
        url: this.editMode
            ? `/admin/seoCities/update/${this.serviceInput.id}`
            : `/admin/seoCities`,
        data: this.serviceInput,
      };

      this.$http(requestData)
          .then((response) => {
            let message = "خدمات با موفقیت اضافه شد";
            if (this.editMode) message = "خدمات با موفقیت ویرایش شد";
            this.$bvToast.toast(message, {
              title: this.$t("Done"),
              variant: "success",
              solid: true,
            });
            if (!this.editMode && this.buildings && this.buildings.length > 0) {
              this.$refs.residencesPaginationComponent.clear();
              // this.$refs.residencesSearchComponent.clear();
              this.$refs.residencesTableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.getServices();
          })
          .catch((err) => {
            let message = "خطا در ایجاد خدمات";
            if (this.editMode) message = "خطا در ویرایش خدمات";
            if (
                err.response &&
                err.response.data &&
                err.response.data.response &&
                err.response.data.response.message
            )
              message = err.response.data.response.message;
            if (
                err.response &&
                err.response.data &&
                err.response.data.response &&
                err.response.data.response.meta &&
                err.response.data.response.meta.errors
            )
              this.errorMessage = err.response.data.response.meta.errors;
            console.log("this.errorMessage", this.errorMessage);

            this.$bvToast.toast(message, {
              title: this.$t("Error"),
              variant: "danger",
            });
            this.submitLoading = false;
            console.log(err);
          });
    },
    getBuildings(search, loading) {
      if (typeof this.buildingsCancelToken != typeof undefined) {
        this.buildingsCancelToken.cancel(
            "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.buildingsCancelToken = this.$http.CancelToken.source();

      let data = {
        search: search,
        per_page: 10,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "buildings",
        params: data,
        cancelToken: this.buildingsCancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.buildings = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
